import React, { useReducer, useState } from 'react';
import Alert from '@paljs/ui/Alert';
import { Container, Row, Col } from 'react-bootstrap';
import { useQuery, useMutation, gql } from '@apollo/client';
import { Card, CardBody, CardHeader } from '@paljs/ui/Card';
import { Editor } from '@tinymce/tinymce-react';
import { InputGroup } from '@paljs/ui/Input';
import styled from 'styled-components';
import ImageUploader from 'react-images-upload';
import { Button } from '@paljs/ui/Button';
import { Checkbox } from '@paljs/ui/Checkbox';

const Input = styled(InputGroup)`
  margin-bottom: 10px;
`;

const SAVE_PLACE_MUTATION = gql`
  mutation onNewPlace($placeInput: PlaceInput!) {
    onNewPlace(place: $placeInput)
  }
`;

const IMAGE_UPLOAD_MUTATION = gql`
  mutation onFileUpload($file: String!) {
    onFileUpload(file: $file)
  }
`;

const QUERY = gql`
  query {
    allTags {
      slug
      name
    }
  }
`;

export default function Create() {
  const [fields, setFields] = useState<any>({ tags: [] });
  const [, forceUpdate] = useReducer((x) => x + 1, 0);
  const [saveErrors, setSaveErrors] = useState('');
  const [alertText, setAlertText] = useState<string | null>(null);
  const [alertType, setAlertType] = useState('success');
  const [tags, setTags] = useState<any[]>([]);
  const [reducerList, dispatch] = useReducer((state: any, action: any) => {
    state[action.item] = action.value;
    setFields(state);

    return state;
  }, {});

  const [uploadImage] = useMutation(IMAGE_UPLOAD_MUTATION);
  const [savePlace] = useMutation(SAVE_PLACE_MUTATION);

  const { data, error } = useQuery(QUERY);

  if (data && tags.length < 1) {
    setTags(data.allTags);
  }

  const LIST = [
    {
      type: 'text',
      title: 'Name',
      item: 'name',
      placeholder: 'my coffee company',
      required: true,
    },
    {
      type: 'image',
      title: 'Logo',
      item: 'logo',
      required: true,
    },
    {
      type: 'image',
      title: 'Cover Image',
      notes: ['* Preferred dimensions are 1200px wide x 450px tall'],
      item: 'coverImage',
      required: true,
    },
    {
      type: 'images',
      title: 'Featured Images',
      item: 'photos',
    },
    {
      type: 'text',
      title: 'Headline',
      item: 'headline',
      placeholder: 'Premium Coffee Roaster for communities',
      required: true,
    },
    {
      type: 'text',
      title: 'Description',
      item: 'description',
      required: true,
    },
    {
      type: 'text',
      title: 'Address',
      item: 'address',
      required: true,
    },
    {
      type: 'text',
      title: 'City',
      item: 'city',
      required: true,
    },
    {
      type: 'text',
      title: 'Postal Code',
      item: 'postalCode',
      required: true,
    },
    {
      type: 'text',
      title: 'Region / State',
      item: 'region',
      required: true,
    },
    {
      type: 'float',
      title: 'latitude',
      item: 'latitude',
      required: true,
      notes: ['Visit https://www.latlong.net/ to find latitude & longitude by address'],
    },
    {
      type: 'float',
      title: 'longitude',
      item: 'longitude',
      required: true,
      notes: ['Visit https://www.latlong.net/ to find latitude & longitude by address'],
    },
    {
      type: 'text',
      title: 'Website',
      item: 'url',
      placeholder: 'https://mycompany.com',
    },
    {
      type: 'text',
      title: 'Facebook',
      item: 'facebook',
      placeholder: 'https://facebook.com/myplace',
    },
    {
      type: 'text',
      title: 'Instagram',
      item: 'instagram',
      placeholder: 'https://instagram.com/myprofile',
    },
    {
      type: 'text',
      title: 'Twitter',
      item: 'twitter',
      placeholder: 'https://twitter.com/myprofile',
    },
    {
      type: 'checkbox',
      title: 'Coffee Shop',
      item: 'isShop',
    },
    {
      type: 'checkbox',
      title: 'Coffee Roaster',
      item: 'isRoaster',
    },
  ];

  const onSingleImageDrop = async (item: string, evt: any) => {
    const upload = new Promise<any>((resolve) => {
      var reader = new FileReader();

      reader.onload = async (e) => {
        const { data, errors } = await uploadImage({
          variables: {
            file: e.target.result,
          },
        });

        resolve({ item, value: data.onFileUpload });
      };

      reader.onerror = function (e) {
        // error occurred
        console.log('Error : ' + e.type);
      };

      reader.readAsDataURL(evt[0]);
    });

    const data = await upload;
    await dispatch(data);
    forceUpdate();
  };

  const onImageListDrop = async (item: string, evt: any) => {
    const upload = new Promise<any>((resolve) => {
      var reader = new FileReader();

      reader.onload = async (e) => {
        // binary data
        const { data, errors } = await uploadImage({
          variables: {
            file: e.target.result,
          },
        });

        const list = fields[item] || [];
        list.push(data.onFileUpload);
        resolve({ item, value: list });
      };

      reader.onerror = function (e) {
        // error occurred
        console.log('Error : ' + e.type);
      };

      reader.readAsDataURL(evt[0]);
    });

    const data = await upload;
    await dispatch(data);
    forceUpdate();
  };

  const removeImageFromList = async (item: string, key: number) => {
    const list = fields[item];
    list.splice(key, 1);
    await dispatch({ item, value: list });
    forceUpdate();
  };

  const updateTags = async (slug: string) => {
    const list = fields.tags;
    const slugIndex = list.findIndex((listSlug) => listSlug === slug);

    if (slugIndex > -1) {
      list.splice(slugIndex, 1);
    } else {
      list.push(slug);
    }
    dispatch({ item: 'tags', value: list });
    forceUpdate();
  };

  const handleSaveClick = async () => {
    try {
      const { data, errors } = await savePlace({
        variables: { placeInput: fields },
      });

      if (data.onNewPlace) {
        window.location.href = '/places';
      } else {
        setAlertText(`failed to save. Check fields and try again`);
        setAlertType('Danger');

        setTimeout(() => {
          setAlertText(null);
        }, 4000);
      }
    } catch (err) {
      setAlertText(`failed to save. Check fields and try again.`);
      setAlertType('Danger');

      setTimeout(() => {
        setAlertText(null);
      }, 4000);
    }
  };

  return (
    <>
      {alertText && (
        <Alert
          style={{ boxShadow: '1px 1px 3px #ccc', position: 'fixed', top: '5.5rem', zIndex: 500 }}
          status={alertType}
        >
          {alertText}
        </Alert>
      )}
      <Row className="justify-content-center">
        <Col xs={12}>
          <h2 style={{ textTransform: 'uppercase', textAlign: 'center', padding: '1.5em', marginBottom: '1.5em' }}>
            Create New Coffee Place
          </h2>
        </Col>
        <Col xs={12} lg={9}>
          {LIST.map((i, key) => (
            <Card key={key}>
              <header>{i.title}:</header>
              <CardBody>
                {i.type === 'text' && (
                  <Input fullWidth size="Medium">
                    <input
                      type="text"
                      placeholder={i.placeholder}
                      onChange={(e) => {
                        dispatch({ item: i.item, value: e.target.value });
                      }}
                    />
                  </Input>
                )}

                {i.type === 'float' && (
                  <Input fullWidth size="Medium">
                    <input
                      type="number"
                      placeholder={i.placeholder}
                      onChange={(e) => {
                        dispatch({ item: i.item, value: parseFloat(e.target.value) });
                      }}
                    />
                  </Input>
                )}

                {i.type === 'checkbox' && (
                  <div>
                    <Checkbox
                      checked={fields[i.item] || false}
                      onChange={() => {
                        dispatch({ item: i.item, value: !fields[i.item] });
                        forceUpdate();
                      }}
                    />
                  </div>
                )}

                {i.type === 'image' && (
                  <div>
                    {fields[i.item] && fields[i.item] !== '' && (
                      <div>
                        <img src={fields[i.item]} style={{ maxWidth: '200px', maxHeight: '100px' }} />
                        <br />
                        <a
                          style={{ cursor: 'pointer' }}
                          onClick={() => {
                            dispatch({ item: i.item, value: '' });
                            forceUpdate();
                          }}
                        >
                          remove
                        </a>
                      </div>
                    )}
                    <ImageUploader
                      withIcon={true}
                      buttonText="Choose images"
                      singleImage={true}
                      onChange={(evt) => {
                        onSingleImageDrop(i.item, evt);
                      }}
                      imgExtension={['.jpg', '.png', '.jpeg']}
                      maxFileSize={5242880}
                    />
                  </div>
                )}

                {i.type === 'images' && (
                  <div>
                    {fields[i.item] &&
                      fields[i.item].map((img: string, key: number) => (
                        <div key={key} style={{ display: 'inline-block' }}>
                          <img key={key} src={img} style={{ maxWidth: '200px', maxHeight: '100px' }} />
                          <br />
                          <a
                            style={{ cursor: 'pointer' }}
                            onClick={() => {
                              removeImageFromList(i.item, key);
                            }}
                          >
                            remove
                          </a>
                        </div>
                      ))}

                    <ImageUploader
                      withIcon={true}
                      buttonText="Choose images"
                      singleImage={true}
                      onChange={(evt) => {
                        onImageListDrop(i.item, evt);
                      }}
                      imgExtension={['.jpg', '.gif', '.png', '.gif']}
                      maxFileSize={5242880}
                    />
                  </div>
                )}

                {i.required && (
                  <p>
                    <em>Required</em>
                  </p>
                )}
                {i.notes && (
                  <div>
                    {i.notes.map((n, k) => (
                      <p key={k}>{n}</p>
                    ))}
                  </div>
                )}
              </CardBody>
            </Card>
          ))}
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col lg={9}>
          <Card>
            <CardHeader>Available Tags</CardHeader>
            <CardBody>
              {tags &&
                fields.tags &&
                tags.length > 0 &&
                tags.map((t, k) => (
                  <Checkbox
                    style={{ margin: '.5rem .75rem' }}
                    checked={!!fields.tags.find((slug) => t.slug === slug)}
                    onChange={() => {
                      updateTags(t.slug);
                    }}
                    key={k}
                  >
                    {t.name}
                  </Checkbox>
                ))}
            </CardBody>
          </Card>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col sm={6} md={4}>
          <Button onClick={handleSaveClick}>Save</Button>
          <p style={{ color: '#f00' }}>{saveErrors}</p>
        </Col>
      </Row>
    </>
  );
}
